import * as Api from "static/js/app/api/endpoints";
import { Page } from "static/js/app/modules/page";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import * as Models from "static/js/app/models/__index";
import Dropzone, { DropzoneFile, DropzoneOptions } from "dropzone";
import "node_modules/foundation-sites/dist/js/foundation";
import { registerCall } from "static/js/app/utils/asyncUtil";
import { map } from "jquery";
import { ServiceQuotationToolConfig } from "static/js/app/hugoSettings/hugoSiteConfig";
import DOMPurify from "dompurify";

export class ValuationPartExchange {
    
    private partExchangeForm: HTMLFormElement;
    private partExchangeResultsForm: HTMLFormElement;
    private partExchangeCTA: HTMLInputElement;
    private maxFileSize = 10485760;
    private minFileSize = 1000;
    private imageFileLimit = 10;
    private dropzone: Dropzone;
    private lookupResultEl : HTMLElement;
    private lookupVrmEl : HTMLElement;

    constructor(private partExchangeFormElementSelector: string, private partExchangeResultsFormElementSelector: string, private partExchangeCTASelector: string = null, private formType: string = null, private serviceQuotationToolConfig: ServiceQuotationToolConfig = null) {
      
        Page.ready(() => {
            this.partExchangeForm = document.querySelector<HTMLFormElement>(this.partExchangeFormElementSelector);
            this.partExchangeResultsForm = document.querySelector<HTMLFormElement>(this.partExchangeResultsFormElementSelector);
            console.log('vehicle valuation');
            if (this.partExchangeForm == null) {
                console.log('form not found');
                return;
            }

            if (this.partExchangeCTASelector)
            {     
                
                this.partExchangeCTA = document.querySelector<HTMLInputElement>(this.partExchangeCTASelector); 
                if (this.partExchangeCTA)
                {
                    this.partExchangeCTA.addEventListener("click", event=> this.ShowPartExchangeForm(event));
                }
            }
            $(this.partExchangeForm).on("keydown", ":input:not(textarea):not(:submit)", function(event) {
                if (event.key == "Enter") {
                    event.preventDefault();
                }
            });
            this.lookupResultEl = this.partExchangeResultsForm.querySelector("#lookup-results");
            this.lookupVrmEl = this.partExchangeForm.querySelector("#lookup");
            // $(this.lookupResultEl).hide();
            this.ConfigureEditButtons(serviceQuotationToolConfig, formType);
            this.SetPageTitleFieldValue();
            this.SetPageUrlFieldValue();
            

            
            this.ConfigureVRMLookupButton();
          
            if(formType != "Service Quotation Tool") {
                this.InitPXImageControl();
            }
            this.SetupTabEvents(serviceQuotationToolConfig);
        });
    }

    private ShowPartExchangeForm(e: Event)
    {
        this.partExchangeForm.classList.remove("hide");        
    }

    private SetVehicleTitleFieldValue(vehicle: Models.Vehicle) {
        if (vehicle == null)
        {
           this.partExchangeForm.querySelector<HTMLInputElement>("[name=pxVehTitle]").value = '';
           return;
        }
        this.partExchangeForm.querySelector<HTMLInputElement>("[name=pxVehTitle]").value = `${[vehicle.year_built,vehicle.manufacturer_display,vehicle.model,vehicle.derivative_display].join(' ')}`;        
    }

    private SetPageTitleFieldValue() {
        this.partExchangeForm.querySelector<HTMLInputElement>("[name=pageTitle]").value = document.title;
    }

    private SetPageUrlFieldValue() {
        this.partExchangeForm.querySelector<HTMLInputElement>("[name=pageUrl]").value = document.URL;
    }

    private ConfigureEditButtons(serviceQuotationToolConfig: ServiceQuotationToolConfig, formType?: string)
    {
        // const pxVRMEditVehicleElements= this.partExchangeForm.querySelectorAll<HTMLElement>(".px-vrm-editvehicle");
        // [].forEach.call(pxVRMEditVehicleElements, (el: Element) => {
        //     el.addEventListener("click", event => this.DisplayEditVehicleTab(event));
        // });
        const pxVRMEditContactElements= this.partExchangeForm.querySelectorAll<HTMLElement>(".px-vrm-editcontact");     
        [].forEach.call(pxVRMEditContactElements, (el: Element) => {
            el.addEventListener("click", event => 
            { 
                const elSqtPrimary = this.partExchangeForm.querySelector("#sqt-primary") as HTMLInputElement;
                const elPxVrmCondition = this.partExchangeForm.querySelector("#px-vrm-condition") as HTMLInputElement;
                if (
                    ((elSqtPrimary != null) && ValuationPartExchange.ValidateField(elSqtPrimary)) ||
                    ((elPxVrmCondition != null) && ValuationPartExchange.ValidateField(elPxVrmCondition))
                ) {
                    $('#your-vehicle-tab-link').addClass("completed-tab");     
                    $('#valuation-tabs').foundation("selectTab","your-details"); 
                    return true;
                }
                else
                {
                    $('#your-vehicle-tab-link').removeClass("completed-tab");   
                    event.preventDefault(); 
                    return false;  
                }
               
                
            });
        }); 
        // const pxEditRegistrationElement= this.partExchangeResultsForm.querySelector<HTMLElement>("#px-edit-registration");  

        // if(pxEditRegistrationElement)
        // {
        //     pxEditRegistrationElement.addEventListener("click", event => { event.preventDefault(); $('#valuation-tabs').foundation("selectTab","your-vehicle"); this.ToggleVrmLookupResult(false); return false;  });
        // }

        const pxMoveToPreviewButton = this.partExchangeResultsForm.querySelector<HTMLElement>("#px-preview");
        pxMoveToPreviewButton.addEventListener("click", event => this.ValidateContactForm(event, serviceQuotationToolConfig));
        
       
    }
    private SetupTabEvents(serviceQuotationToolConfig: ServiceQuotationToolConfig)
    {
        $('#valuation-tabs').on('change.zf.tabs', ()=> {
            var currentTab = this.partExchangeResultsForm.querySelector<HTMLElement>("li.is-active"); 
            var steps = this.partExchangeResultsForm.querySelectorAll<HTMLElement>(".current-step");
            [].forEach.call(steps, (el: Element) => {
                el.classList.remove("active-tab");
            }); 
            var stepLabel = currentTab.querySelector<HTMLElement>(".current-step");
                stepLabel.classList.add("active-tab");
            if ($('#confirmation:visible').length) {
                this.SetPreviewFields();
            }
         });
    }

    private DisplayEditVehicleTab(e: Event)
    {
        
        //e.preventDefault();
        $('#valuation-tabs').foundation("selectTab","your-vehicle");

        const regEl = this.partExchangeResultsForm.querySelector<HTMLElement>("#px-vrm-registration");
            
        const showResult = (
            (regEl.innerText.length > 0) ||
            (
                (regEl.tagName == "INPUT" || regEl.tagName == "SELECT") && 
                (regEl as HTMLInputElement).value.length > 0
            )
        );
        
        // this.ToggleVrmLookupResult(showResult);

        return true;
        //return false;
    }

    private ToggleVrmLookupResult(showResult: boolean)
    {
        // if (showResult)
        // {
        //     $(this.lookupResultEl).show();
        //     $(this.lookupVrmEl).hide();
        // }
        // else
        // {
        //     $(this.lookupResultEl).hide();
        //     $(this.lookupVrmEl).show();
        // }                        
    }


    private acceptFile(file: FMGDropzoneFile, done: (error?: string | Error) => void) {
        let dropzone = this.dropzone;
        if (!this.dropzone)
        {
            dropzone = Dropzone.instances[0];
        }
        if (!file.type.match("image/jpeg|image/jpg|image/png|image/webp") || file.size > this.maxFileSize || file.size < this.minFileSize) {
            console.log("file type or size error");
           done("Please only upload jpeg or png images file of a size less than 10Mb");
           return;
        }
        var files = dropzone.files.length;
        console.log(`files uploaded ${files}`);
        if (files > 10) {
            console.log("too many files");
            done("Please only upload a maximum of 10 files");
            dropzone.emit("maxfilesexceeded", file);
            return;
        } 
        
        console.log(`file accepted ${file.name}`);

        Api.PartEx.ConfigureImageUpload(new Array<Models.PartExchangeImageFile>(new Models.PartExchangeImageFile(file.name, file.type) )) 
          .then((url) => {
          
            file.uploadUrl = url[0].uploadUri;
            file.imageUrl = url[0].imagePath;
            file.thumbnailUrl = url[0].thumbnailPath            
            file.accepted = true;
            
      
            done()
          })
          .catch((err) => {
            file.accepted = false;
            done(`Failed to get an S3 signed upload URL ${err}`);
          });
      }

     
private getDropzoneOptions(): DropzoneOptions
{
    return {   
        url: "/px/",
        method: "put",
        parallelUploads: 1,
        thumbnailHeight: 120,
        thumbnailWidth: 120,            
        maxThumbnailFilesize: 10,
        maxFiles: this.imageFileLimit,            
        maxFileSize: 10,
        uploadMultiple: false,                            
        autoProcessQueue: true,            
        acceptedFiles: ".png,.jpg,.jpeg",    
        resizeMethod: "contain",            
        addRemoveLinks: false,
        dictRemoveFile: "Delete",        
        timeout: 3600000,
        previewTemplate: this.dropzonePreview,
        dictDefaultMessage: this.dropzoneMessage,
       sending: this.SendFile,
        accept: this.acceptFile
        

        
} as DropzoneOptions;
}
    private InitPXImageControl() {


            this.dropzone = new Dropzone("div#image_upload", this.getDropzoneOptions()
             );
            this.dropzone.accept = this.acceptFile;
            
            this.dropzone.on("processing", (file:FMGDropzoneFile) =>
            {               
                this.dropzone.options.url = file.uploadUrl;  
            });
            this.dropzone.on("queuecomplete", () =>
            {               
                this.partExchangeResultsForm.querySelector("#image_upload").classList.remove("dz-started")
                
            });
    }
    private ConfigureVRMLookupButton() {
        const pxVRMLookup = this.lookupVrmEl.querySelector<HTMLInputElement>("#px-vrm-lookup-button");
        if(pxVRMLookup != null) {
            pxVRMLookup.addEventListener("click", event => this.PerformVRMLookup(event));
        }
    }


    private SendFile(file:FMGDropzoneFile, xhr: XMLHttpRequest)
    {
                let _send = xhr.send;    
                xhr.send = function() {     
                        let dropzone = this.dropzone as Dropzone; 
                        if (!this.dropzone)
                        {
                            dropzone = Dropzone.instances[0];
                        }                                           
                        dropzone.resizeImage(file,1536,1536,"contain", (fileBlob: Blob)=> { 
                            _send.call(xhr, fileBlob);      
                        });       
                }

    }
    private ValidateContactForm(e: Event, serviceQuotationToolConfig: ServiceQuotationToolConfig) {
        const elements = this.partExchangeResultsForm.querySelector("#px-contact-info").querySelectorAll<HTMLInputElement>("input");
        e.preventDefault();
        for (let i = 0; i < elements.length; i++) {
            if (ValuationPartExchange.ValidateField(elements[i]) === false) {
                $('#your-details-tab-link').removeClass("completed-tab");

                return false;
            }
        }
  
        $('#your-details-tab-link').addClass("completed-tab");
        this.SetPreviewFields();
        $('#valuation-tabs').foundation("selectTab","confirmation");
        return true;
    }

    private GenerateImagePreviews() {
        const uploadedImagePreviews = this.partExchangeResultsForm.querySelector<HTMLElement>("#px-form-preview-images");
        if(uploadedImagePreviews == null) {
            return;
        }

        const uploadedFiles = this.dropzone.getAcceptedFiles();

        if (uploadedFiles != null && uploadedFiles.length > 0) {
            uploadedImagePreviews.innerHTML = "";
            [].forEach.call(uploadedFiles, (image: FMGDropzoneFile) => {
                
                let previewThumbnail = image.previewElement.querySelector("img");
                let imageLink = document.createElement("a");
                imageLink.href = image.thumbnailUrl;

                let imageEl = document.createElement("img");
                imageEl.setAttribute("src", previewThumbnail.src);
                imageEl.classList.add("image-preview");
                imageLink.appendChild(imageEl);
                let imageUrl = document.createElement("input");
                imageUrl.name = "pxImageUrl";
                imageUrl.hidden = true;
                imageUrl.setAttribute("value", image.imageUrl);         
                uploadedImagePreviews.appendChild(imageLink);
                uploadedImagePreviews.appendChild(imageUrl);
                (<any>$(imageLink)).magnificPopup({
                    closeOnContentClick: true,
					type: 'image'
				});
            });
          
        }
    }

    private async PerformVRMLookup(event: MouseEvent) {
        const regField = this.partExchangeForm.querySelector<HTMLInputElement>("#px-reg");
        const mileageField = this.partExchangeForm.querySelector<HTMLInputElement>("#px-mileage");
        const conditionField = this.partExchangeForm.querySelector<HTMLInputElement>("#px-condition");
        const firstNameField = this.partExchangeForm.querySelector<HTMLInputElement>("#px-first-name");
        const lastNameField = this.partExchangeForm.querySelector<HTMLInputElement>("#px-last-name");
        const emailField = this.partExchangeForm.querySelector<HTMLInputElement>("#px-email");
        const allowMarketing = document.querySelector<HTMLInputElement>('input[name="allowMarketing"]:checked');
        
        const isAllowMarketing = (allowMarketing != null ? allowMarketing.value : null);

        if (ValuationPartExchange.ValidateField(regField) === false || ValuationPartExchange.ValidateField(mileageField) === false) {
            return;
        }
        const vrm = regField.value.toUpperCase().replace(" ", "");
        //show spinner?\
        let vrmRequest = new Models.VRMRequest(vrm,true,Number.parseInt(mileageField.value))
        let vrmresult: Models.VRMResult
        try{
            vrmresult = await Api.PartEx.vrmlookup(vrmRequest);
        }
        catch(e) 
        {
            console.debug(`VRM lookup failed for ${regField.value}`);     
            regField.setCustomValidity(`We are unable to recognise this registration ${regField.value}. Please try again or alternatively send us an enquiry with the details and we'll get back to you`);
            this.partExchangeForm.reportValidity();
            return;
        }          
        if (vrmresult != null && vrmresult.isSuccessful) {
            try {
                let initialEnquiryResult = this.sendInitialEnquiry(vrm, mileageField.value, conditionField.value, firstNameField.value, lastNameField.value, emailField.value, isAllowMarketing, vrmresult);
                if (!initialEnquiryResult)
                {
                    console.log("Error posting initial enquiry");
                }
            }
            catch(e)
            {
                console.log("Error posting initial enquiry");
            }
            this.MapVehicleFields(regField.value.toUpperCase(), mileageField.value, conditionField.value, firstNameField.value, lastNameField.value, emailField.value, vrmresult);
            this.MapServices(vrmresult);
            $('#vehicleValuationModal').foundation('open');
            //this.ToggleVrmLookupResult(true);

            //Populate VRM vehicle fields
        }
        else {
            console.debug(`VRM lookup failed for ${regField.value}`);
            regField.setCustomValidity(`We are unable to recognise this registration ${regField.value}. Please try again or alternatively send us an enquiry with the details and we'll get back to you`);
            this.partExchangeForm.reportValidity();
            //this.ToggleVrmLookupResult(false);
        }
     //   }
    
    }

    private async sendInitialEnquiry(vrm: string, mileage: string, condition: string, firstName: string, lastName: string, email: string, allowMarketing: string, vrmResult: Models.VRMResult) {
        const formAction = this.partExchangeResultsForm.action;
        
        const formData = new FormData();
        formData.append('isPartialPXValuation', 'true');
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('reg', vrm);
        formData.append('condition', condition);
        if (allowMarketing != null)
        {
            formData.append('allowMarketing', allowMarketing);
        }
        else{
            formData.append('skipmarketingconsent', "do not remove");
        }
       
        formData.append('year', StringFormatting.coerceNullishToBlank(vrmResult.year));
        formData.append('colour', vrmResult.colour);
        formData.append('fuel', vrmResult.fuel);
        formData.append('mileage', mileage);
        formData.append('make', vrmResult.make);
        formData.append('model', vrmResult.model);
        formData.append('derivative', vrmResult.derivative);
        formData.append('motExpiry', StringFormatting.dateAsFormattedString(vrmResult.motExpiry));
        formData.append('firstRegisteredDate', StringFormatting.dateAsFormattedString(vrmResult.firstRegisteredDate));
        formData.append('owners',  vrmResult.numberOfOwners.toString());
        
        const formString = new URLSearchParams(formData as any).toString();
       
        const response = await fetch(formAction, {
            method: 'POST',
            body: formString,
            headers: {'Content-Type': 'application/x-www-form-urlencoded','accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7'}});
          
          if (!response.ok) { 
            console.log(response.status);
            return false;
          }
          return true;
    }

    private readonly dropzonePreview: string =      
    `<div class="dz-preview" style="background:none;">
    <div class="dz-image"><img data-dz-thumbnail /></div>
    <div class="dz-details">
        <div style="position: absolute;top:120px;left: 0;right: 0; bottom: 0;margin: auto;"><a href="javascript:;" class="delete" data-dz-remove="">Delete</a></div>
    </div>
    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
    <div class="dz-error-message"><span data-dz-errormessage></span></div>
    <div class="dz-success-mark">
      <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Check</title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#FFFFFF"></path>
        </g>
      </svg>
    </div>
    <div class="dz-error-mark">
      <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Error</title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475">
            <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"></path>
          </g>
        </g>
      </svg>
    </div>
  </div>`;
  



    private readonly dropzoneMessage: string =
            `<div><div class="note">Drag and drop you images here </div>
                <div style="color:red">or click to upload</div>
                </div>`;
    

    private MapServices(vrmResult: Models.VRMResult)
    {
        const services = document.querySelectorAll("#lookup-results .service-price");

        services.forEach(el => {
            const divEl = el as HTMLDivElement;
            
            if(
                (divEl.dataset["fueltype"].toLowerCase() != vrmResult.fuel.toLowerCase()) || 
                (vrmResult.engineCapacityCC != null && vrmResult.engineCapacityCC <= parseInt(divEl.dataset["engine_min_cc"])) ||
                (vrmResult.engineCapacityCC != null && vrmResult.engineCapacityCC >= parseInt(divEl.dataset["engine_max_cc"]))
            ) {
                divEl.hidden = true;
            }
        });
    }

    private MapVehicleFields(vrm: string, mileage: string, condition: string, firstName: string, lastName: string, email: string, vrmResult: Models.VRMResult) {
        this.SetNodeValue("#px-at-valuation-price", vrmResult.pxValuation == null? "unavailable" :  StringFormatting.coerceNullishToBlank(StringFormatting.numberWithCommas(vrmResult.pxValuation ?? 0)));
        this.SetNodeValue("#atValuation", vrmResult.pxValuation == null? "unavailable" : StringFormatting.coerceNullishToBlank(vrmResult.pxValuation));
        this.SetNodeValue("#px-vrm-vehicle-name", ['Your',vrmResult.year,vrmResult.make,vrmResult.model,vrmResult.derivative ].join(' '));
        this.SetNodeValue("#px-vrm-registration-editable", vrm);
        this.SetNodeValue("#px-reg", vrm);
        this.SetNodeValue("#px-vrm-registration", vrm);
        this.SetNodeValue("#px-vrm-mini-year", StringFormatting.coerceNullishToBlank(vrmResult.year));
        this.SetNodeValue("#px-vrm-mini-colour", vrmResult.colour);
        this.SetNodeValue("#px-vrm-mini-fuel", vrmResult.fuel);
        this.SetNodeValue("#px-vrm-mileage", mileage);
        this.SetNodeValue("#px-vrm-make", vrmResult.make);
        this.SetNodeValue("#px-vrm-model", vrmResult.model);
        this.SetNodeValue("#px-vrm-derivative", vrmResult.derivative);
        this.SetNodeValue("#px-vrm-fuel", vrmResult.fuel);
        this.SetNodeValue("#px-vrm-gearbox", vrmResult.gearbox);
        this.SetNodeValue("#px-vrm-colour", vrmResult.colour);
        this.SetNodeValue("#px-vrm-mot-expiry", StringFormatting.dateAsFormattedString(vrmResult.motExpiry));
        this.SetNodeValue("#px-first-registered-date",  StringFormatting.dateAsFormattedString(vrmResult.firstRegisteredDate));
        this.SetNodeValue("#px-vrm-year", StringFormatting.coerceNullishToBlank(vrmResult.year));
        this.SetNodeValue("#px-vrm-owners", vrmResult.numberOfOwners.toString());
        this.SetNodeValue("#px-vrm-make-logo", vrmResult.make);
        this.SetNodeValue("#sub-mileage", mileage);
        this.SetNodeValue("#sub-make", vrmResult.make);
        this.SetNodeValue("#sub-model", vrmResult.model);
        this.SetNodeValue("#sub-derivative", vrmResult.derivative);
        this.SetNodeValue("#sub-fuel", vrmResult.fuel);
        this.SetNodeValue("#sub-gearbox", vrmResult.gearbox);
        this.SetNodeValue("#sub-colour",  vrmResult.colour);
        this.SetNodeValue("#px-vrm-condition",  condition);
        this.SetNodeValue("#px-vrm-first-name",  firstName);
        this.SetNodeValue("#px-vrm-last-name",  lastName);
        this.SetNodeValue("#px-vrm-email",  email);

    }

    private SetNodeValue(selector: string, value: string)
    {
        const el = this.partExchangeResultsForm.querySelector<HTMLElement>(selector);
        if(el != null) {
            switch(el.tagName) {
                case "INPUT":
                case "SELECT":
                    (el as HTMLInputElement).value = value;
                    break;
                case "IMG":
                    (el as HTMLInputElement).src = "https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/manufacturer-logos/" + value.toLowerCase() + ".png";
                    break;
                default:
                    el.innerText = value;
                    break;
            }
        }
    }

    private SetFieldValue(fieldName: string, getFromFieldQuerySelector: string, useYesNoBooleanValue: boolean = false)
    {
        var elements = this.partExchangeResultsForm.querySelectorAll(`.${fieldName}`);
        console.log(fieldName);

        [].forEach.call(elements, (element: Element) => {

            const srcElement = this.partExchangeResultsForm.querySelector<HTMLElement>(getFromFieldQuerySelector);
         

            if(srcElement != null) {
                let srcValue = "";
                console.log(srcElement.tagName);
                if (srcElement.tagName === "INPUT")
                {
                    const inputEl = (srcElement as HTMLInputElement);
                    srcValue = (useYesNoBooleanValue) ? (inputEl.value === "true") ? "yes"  : "no" : inputEl.value;
                }
                else if(srcElement.tagName == "SELECT")
                {
                    const inputEl = (srcElement as HTMLInputElement);
                    srcValue = (useYesNoBooleanValue) ? (inputEl.value === "true") ? "yes"  : "no" : inputEl.value;
                }
                else if(srcElement.tagName == "TEXTAREA")
                {
                    srcValue = (srcElement as HTMLTextAreaElement).value;
                }
                else
                {
                    srcValue = srcElement.textContent;
                }

                if (element.tagName === "INPUT" || element.tagName === "SELECT")
                {
                    $(element).val(srcValue);
                } else 
                {
                    element.textContent = srcValue;
                }
            }
        });

    }

    private SetPreviewFields() {
        this.SetFieldValue("px-preview-mileage", "#px-vrm-mileage");
        this.SetFieldValue("px-preview-registration", "#px-vrm-registration");
        this.SetFieldValue("px-preview-make", "#px-vrm-make");
        this.SetFieldValue("px-preview-model", "#px-vrm-model");
        this.SetFieldValue("px-preview-derivative", "#px-vrm-derivative");
        this.SetFieldValue("px-preview-fuel", "#px-vrm-fuel");
        this.SetFieldValue("px-preview-gearbox", "#px-vrm-gearbox");
        this.SetFieldValue("px-preview-colour", "#px-vrm-colour");
        this.SetFieldValue("px-preview-owners", "#px-vrm-owners");
        this.SetFieldValue("px-preview-berth", "#px-vrm-berth");
        this.SetFieldValue("px-preview-outstanding-finance", "#px-vrm-outstanding-finance");
        this.SetFieldValue("px-preview-condition", "#px-vrm-condition");
        this.SetFieldValue("px-preview-taxi", "#taxi",true);
        //this.SetFieldValue("px-preview-hireVehicle", "#hireVehicle");
        this.SetFieldValue("px-preview-animal", "#animalTransport",true);
        this.SetFieldValue("px-preview-smoker", "#smoker",true);
        this.SetFieldValue("px-preview-mot-expiry", "#px-vrm-mot-expiry");  
        this.SetFieldValue("px-preview-service-history", "#px-vrm-service-history");  
        this.SetFieldValue("px-preview-additional", "[name='additional']");  
        this.SetFieldValue("px-preview-first-name", "[name='firstName']");
        this.SetFieldValue("px-preview-last-name", "[name='lastName']");
        this.SetFieldValue("px-preview-email", "[name='email']");        
        this.SetFieldValue("px-preview-phone", "[name='phone']");  
        this.SetFieldValue("px-preview-postcode", "[name='postcode']");
        //this.SetFieldValue("px-preview-primary-service", "[name='primary-service']:checked", true);

        this.GenerateImagePreviews();
      
    }

    private static ValidateField(fieldToValidate: HTMLInputElement, validationString: string = null): boolean {
        if(fieldToValidate != null && fieldToValidate.id == "sqt-primary") {
            const radios = fieldToValidate.querySelectorAll(".sqt-service-type");

            for(let i=0; i<radios.length; i++)
            {
                if((radios[i] as HTMLInputElement).checked)
                {
                    return true;
                }
            }
        }

        fieldToValidate.setCustomValidity('');

        if (fieldToValidate.checkValidity()) {
            return true;
        }

        fieldToValidate.setCustomValidity(validationString || fieldToValidate.dataset.validationError)

        //IE doesn't support this function so we have to polyfil with this crazy code
        if (typeof fieldToValidate.reportValidity !== "function") {            
            const button = document.createElement("button")
            fieldToValidate.form.appendChild(button)
            button.click()
            fieldToValidate.form.removeChild(button)

            return false;
        }
        else {
            fieldToValidate.reportValidity();
            return false;
        }
    }

   
}

interface FMGDropzoneFile extends DropzoneFile
{
    uploadUrl: string;
    thumbnailUrl: string;
    imageUrl: string 
}
