import { VehicleImageUrls } from "static/js/app/models/vehicleImageUrls";
export function buildVehicleImageThumbnails (images:VehicleImageUrls[], numberOfExtraImages:number): string {
  if(numberOfExtraImages == null){
    numberOfExtraImages = 8;
  }
  let numberOfExtraImagesCalc = numberOfExtraImages + 1;
    if (images == null || images.length == 0 || numberOfExtraImages == null || numberOfExtraImages == 0 || images.length < numberOfExtraImagesCalc ) {
      return "";
    }
    else {
      
      let thumbImages = images.slice(1,numberOfExtraImagesCalc)
                              .map(im=> `<li>
                              <picture>
                                <source type="image/webp" srcset="${im.i320x240}.webp">
                                <source type="image/jpeg" srcset="${im.i320x240}">
                                <img src="${im.i320x240}" class="">
                              </picture>
                              </li>`)
                              .join("");
      let thumbs = `<ul class="vehicle-results-thumbnails">${thumbImages}</ul>`;
      return thumbs;
    }
}
export function buildVehicleImageThumbnailsSlick (images:VehicleImageUrls[], numberOfExtraImages:number): string {
    if (images == null || images.length <= 1 || numberOfExtraImages == null || numberOfExtraImages <= 1) {
        return "";
    }
    else {
      
      let thumbImages = images.slice(0,numberOfExtraImages)
                              .map(im=> `<li>
                              <picture>
                                <source type="image/webp" srcset="${im.i320x240}.webp">
                                <source type="image/jpeg" srcset="${im.i320x240}">
                                <img src="${im.i320x240}" data-placeholder="/basemedia/placeholder.jpg">
                              </picture>
                              </li>`)
                              .join("");
      let thumbs = `<ul class="slick slick--vehicle-results-thumbnails vehicle-results-thumbnails">${thumbImages}</ul>`;
      return thumbs;
    }
}
